import React, { FocusEvent, ReactNode, useEffect, useMemo, useState } from 'react'

import { FieldError } from 'react-hook-form'

import { useTranslation } from 'next-i18next'

import ArrowDropDown from '@mui/icons-material/ArrowDropDown'
import {
  FilledInputProps,
  FormControl,
  ClickAwayListener,
  Autocomplete,
  AutocompleteRenderInputParams,
} from '@mui/material'

import Magnifier from 'src/components/icons/Magnifier'
import { DataTestIds } from 'src/config/dataTestIds'
import { getPhoneMetadata, PhoneMetadata, SupportedLocale } from 'src/helpers/phoneMetadata'
import useTranslationSwitch from 'src/hooks/useTranslationSwitch'

import FilledInput from '../FilledInput'
import StyledInputAdornment from '../InputAdornment'

import { parseMobilePhoneNumber } from './PhoneInputFilled.helpers'
import * as S from './PhoneInputFilled.styles'

export const getCountrySource = (country: string) => {
  return `${COUNTRY_FLAGS_URL}${country?.toLocaleLowerCase()}.svg`
}

type Props = FilledInputProps & {
  value: string
  dirty: boolean
  name?: string
  inputError?: FieldError
  children?: ReactNode | ReactNode[]
  label?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  dataTestId?: string
}

const COUNTRY_FLAGS_URL = process.env.NEXT_PUBLIC_COUNTRY_FLAGS_URL

const PhoneInputFilled = ({ label = 'Phone', value, name, onChange, dirty, ...rest }: Props) => {
  const [phoneNumberInfo, setPhoneNumberInfo] = useState(undefined)
  const [isDropDownOpen, setIsDropDownOpen] = useState(false)

  const { t } = useTranslation()
  const { currentLanguage } = useTranslationSwitch({})

  async function setPhoneNumer() {
    setPhoneNumberInfo(await parseMobilePhoneNumber(value))
  }

  useEffect(() => {
    if (!dirty && value) {
      setPhoneNumer()
      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, dirty])

  useEffect(() => {
    setPhoneNumer()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const nationalNumber = event.target.value

    setPhoneNumberInfo({ ...phoneNumberInfo, nationalNumber })

    onChange?.({
      target: { value: `+${phoneNumberInfo?.countryCallingCode}${nationalNumber}`, name },
    } as React.ChangeEvent<HTMLInputElement>)
  }

  const handleAutocomplete = (_, phoneMetadata) => {
    if (phoneMetadata) {
      setPhoneNumberInfo({
        ...phoneNumberInfo,
        country: phoneMetadata.iso2code,
        countryCallingCode: phoneMetadata.callingCode,
      })
      setIsDropDownOpen(false)

      onChange?.({
        target: { value: `+${phoneMetadata.callingCode}${phoneNumberInfo?.nationalNumber}`, name },
      } as React.ChangeEvent<HTMLInputElement>)
      rest.onBlur({ target: { name } } as FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>)
    }
  }

  const memorizedPhoneMetadata = useMemo<PhoneMetadata[]>(
    () => getPhoneMetadata(currentLanguage as SupportedLocale),
    [currentLanguage]
  )

  const renderSearchInput = (params: AutocompleteRenderInputParams) => (
    <FilledInput
      {...params}
      dataTestId={DataTestIds.CountrySearch}
      label={t('common.countries_search')}
      InputProps={{
        ...params.InputProps,
        startAdornment: (
          <StyledInputAdornment position="start">
            <Magnifier />
          </StyledInputAdornment>
        ),
      }}
    />
  )

  const renderAutocompleteOption = (props, { iso2code: code, callingCode, name }: PhoneMetadata) => {
    const countryListItemProps = { ...props, 'aria-selected': `${phoneNumberInfo?.country === code}` }

    return (
      <S.CountryListItem data-testid={code} component="li" {...countryListItemProps}>
        <S.Image src={getCountrySource(code)} loading="lazy" width={22} height={22} />
        <S.Country>
          {name[currentLanguage]} +{callingCode}
        </S.Country>
      </S.CountryListItem>
    )
  }

  return (
    <FormControl fullWidth variant="filled">
      <FilledInput
        {...rest}
        shrink
        type="tel"
        label={label}
        name={name}
        value={phoneNumberInfo?.nationalNumber}
        onChange={handleChange}
        startAdornment={
          <StyledInputAdornment position="start">
            <S.FlagTrigger data-testid={DataTestIds.FlagButton} onClick={() => setIsDropDownOpen((v) => !v)}>
              <S.Image src={getCountrySource(phoneNumberInfo?.country)} />
              <ArrowDropDown />
            </S.FlagTrigger>
            <S.CallingCode>+{phoneNumberInfo?.countryCallingCode}</S.CallingCode>
          </StyledInputAdornment>
        }
      />
      {isDropDownOpen && (
        <ClickAwayListener onClickAway={() => isDropDownOpen && setIsDropDownOpen(false)}>
          <S.DropDownContent>
            <Autocomplete
              open
              popupIcon={null}
              noOptionsText={null}
              onChange={handleAutocomplete}
              options={memorizedPhoneMetadata}
              getOptionLabel={(option: PhoneMetadata) => option.name[currentLanguage]}
              PopperComponent={(props) => <S.CountryPopper {...props} />}
              renderOption={renderAutocompleteOption}
              renderInput={renderSearchInput}
            />
          </S.DropDownContent>
        </ClickAwayListener>
      )}
    </FormControl>
  )
}

export default PhoneInputFilled
