import React, { useState, useRef, useEffect, KeyboardEvent } from 'react'

import { SubmitHandler } from 'react-hook-form'

import { Language } from '@dentalux/security'

import * as S from './OtpInput.styles'

export type OTPFields = {
  code: string[]
  rememberDevice?: boolean
}

export interface AllOTPFields extends OTPFields {
  preferredLanguageCode?: Language
}

export interface Props {
  onChange: (otp: string[]) => unknown
  handleSubmit: (fn: SubmitHandler<OTPFields>) => () => void
  onSubmit: SubmitHandler<OTPFields>
  value?: string[]
  length?: number
  disabled?: boolean
  className?: string
  isValid?: boolean
  autoSubmitDelay?: number
  shouldAutoSubmit?: boolean
}

const autocomplete = 'one-time-code'

const OtpInput = ({
  length = 5,
  autoSubmitDelay = 350,
  shouldAutoSubmit,
  isValid,
  handleSubmit,
  onSubmit,
  disabled,
  onChange,
  value = Array(length).fill(''),
}: Props) => {
  const fieldsRef = useRef<HTMLInputElement[]>([])
  const maxFieldIndex = length - 1

  const [focusedInput, setFocusedInput] = useState(0)

  const focusField = (index: number) => {
    fieldsRef.current[index].focus()
    if (fieldsRef.current[index].value) {
      fieldsRef.current[index].select()
    }
  }

  useEffect(() => {
    if (isValid) {
      /**
       * We want to run this only when isValid changes
       */
      fieldsRef.current.forEach((field) => {
        field.blur()
      })

      if (shouldAutoSubmit) {
        setTimeout(() => {
          handleSubmit(onSubmit)()
        }, autoSubmitDelay)
      }
    } else {
      /**
       * and want to run this when code was incorrect and for the first time
       */
      if (!/iPad|iPhone|iPod/.test(navigator.userAgent)) {
        focusField(0)
      }
    }
  }, [isValid])

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    // Allow only strokes with digits, navigation, backspace or paste action
    if (
      !['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'v', 'ArrowRight', 'ArrowLeft', 'Tab'].includes(e.key) &&
      e.keyCode !== 8
    ) {
      e.preventDefault()
    }
  }

  const handleKeyUp = (index: number) => (e: KeyboardEvent<HTMLInputElement>) => {
    if (focusedInput !== 0 && e.keyCode === 8 && !value[index]) {
      focusField(focusedInput - 1)
    }
  }

  const handleChange = (index: number) => (e) => {
    const data = e.nativeEvent.data || e.target.value

    if (data.length === length && !isNaN(Number(data))) {
      onChange(data.split(''))
    } else if ((data.length === 1 && data !== 'v') || data === '') {
      const nextValues = [...value]
      onChange([...nextValues.slice(0, index), data, ...nextValues.slice(index + 1)])

      if (data) {
        if (focusedInput < maxFieldIndex) {
          focusField(focusedInput + 1)
        }
      }
    }
  }

  const handleFocus = (index: number) => () => {
    setFocusedInput(index)
    focusField(index)
  }

  return (
    <S.OtopInputContainer action="#" name={autocomplete}>
      {Array(length)
        .fill('')
        .map((_, index) => {
          return (
            <S.OtpInputField
              key={`optInput-${index}`}
              type="number"
              inputRef={(el) => (fieldsRef.current[index] = el)}
              variant={undefined}
              onPaste={(e) => {
                const text = e.clipboardData.getData('text')

                if (text.length !== length || isNaN(Number(text))) {
                  e.preventDefault()
                }
              }}
              onFocus={handleFocus(index)}
              onChange={handleChange(index)}
              onKeyUp={handleKeyUp(index)}
              onKeyDown={handleKeyDown}
              disabled={disabled}
              value={value[index]}
              className="otpInput"
              inputProps={{
                autocomplete,
                inputMode: 'numeric',
                pattern: '[0-9]*',
                id: `otc-${index}`,
                type: 'tel',
              }}
            />
          )
        })}
    </S.OtopInputContainer>
  )
}

export default OtpInput
