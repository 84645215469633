import React, { useState } from 'react'

import { FieldError } from 'react-hook-form'

import { FilledInputProps, IconButton } from '@mui/material'

import Lock from 'src/components/icons/Lock'
import Visibility from 'src/components/icons/Visibility'
import VisibilityOff from 'src/components/icons/VisibilityOff'

import FilledInput from '../FilledInput'
import StyledInputAdornment from '../InputAdornment'
import PasswordStrength from '../PasswordStrength'

type Props = FilledInputProps & {
  label?: string
  onAutoFill?: () => void
  inputError?: FieldError
  required?: boolean
  strengthIndicator?: boolean
  value: string
  dataTestId?: string
}

const PasswordInputFilled = ({ label = 'Password', strengthIndicator = false, value, ...rest }: Props) => {
  const [showPassword, setShowPassword] = useState<boolean>(false)

  const handleShowPasswordClick = () => {
    setShowPassword(!showPassword)
  }

  return (
    <FilledInput
      {...rest}
      hideHelperText={strengthIndicator}
      label={label}
      type={showPassword ? 'text' : 'password'}
      value={value}
      autoComplete="current-password"
      startAdornment={
        <StyledInputAdornment position="start">
          <Lock />
        </StyledInputAdornment>
      }
      endAdornment={
        <StyledInputAdornment position="end">
          <IconButton aria-label="toggle password visibility" onClick={handleShowPasswordClick} edge="end">
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </StyledInputAdornment>
      }
    >
      {strengthIndicator && <PasswordStrength value={value} />}
    </FilledInput>
  )
}

export default PasswordInputFilled
