export type PhoneMetadata = {
  callingCode: string
  name: {
    en: string
    de: string
  }
  code: string
  iso2code: string
}

export type SupportedLocale = 'de' | 'en' | 'cimode'

export const metadataByCountryCode = {
  DE: {
    callingCode: '49',
    name: {
      en: 'Germany',
      de: 'Deutschland',
    },
    code: 'DEU',
    iso2code: 'DE',
  },
  GB: {
    callingCode: '44',
    name: {
      en: 'United Kingdom',
      de: 'Vereinigtes Königreich',
    },
    code: 'GBR',
    iso2code: 'GB',
  },
  AT: {
    callingCode: '43',
    name: {
      en: 'Austria',
      de: 'Österreich',
    },
    code: 'AUT',
    iso2code: 'AT',
  },
  CH: {
    callingCode: '41',
    name: {
      en: 'Switzerland',
      de: 'Schweiz',
    },
    code: 'CHE',
    iso2code: 'CH',
  },
  FR: {
    callingCode: '33',
    name: {
      en: 'France',
      de: 'Frankreich',
    },
    code: 'FRA',
    iso2code: 'FR',
  },
  BG: {
    callingCode: '359',
    name: {
      en: 'Bulgaria',
      de: 'Bulgarien',
    },
    code: 'BGR',
    iso2code: 'BG',
  },
  US: {
    callingCode: '1',
    name: {
      en: 'United States',
      de: 'Vereinigte Staaten',
    },
    code: 'USA',
    iso2code: 'US',
  },
  AG: {
    callingCode: '1',
    name: {
      en: 'Antigua & Barbuda',
      de: 'Antigua und Barbuda',
    },
    code: 'ATG',
    iso2code: 'AG',
  },
  AI: {
    callingCode: '1',
    name: {
      en: 'Anguilla',
      de: 'Anguilla',
    },
    code: 'AIA',
    iso2code: 'AI',
  },
  AS: {
    callingCode: '1',
    name: {
      en: 'American Samoa',
      de: 'Amerikanisch-Samoa',
    },
    code: 'ASM',
    iso2code: 'AS',
  },
  BB: {
    callingCode: '1',
    name: {
      en: 'Barbados',
      de: 'Barbados',
    },
    code: 'BRB',
    iso2code: 'BB',
  },
  BM: {
    callingCode: '1',
    name: {
      en: 'Bermuda',
      de: 'Bermuda',
    },
    code: 'BMU',
    iso2code: 'BM',
  },
  BS: {
    callingCode: '1',
    name: {
      en: 'Bahamas',
      de: 'Bahamas',
    },
    code: 'BHS',
    iso2code: 'BS',
  },
  CA: {
    callingCode: '1',
    name: {
      en: 'Canada',
      de: 'Kanada',
    },
    code: 'CAN',
    iso2code: 'CA',
  },
  DM: {
    callingCode: '1',
    name: {
      en: 'Dominica',
      de: 'Dominica',
    },
    code: 'DMA',
    iso2code: 'DM',
  },
  DO: {
    callingCode: '1',
    name: {
      en: 'Dominican Republic',
      de: 'Dominikanische Republik',
    },
    code: 'DOM',
    iso2code: 'DO',
  },
  GD: {
    callingCode: '1',
    name: {
      en: 'Grenada',
      de: 'Grenada',
    },
    code: 'GRD',
    iso2code: 'GD',
  },
  GU: {
    callingCode: '1',
    name: {
      en: 'Guam',
      de: 'Guam',
    },
    code: 'GUM',
    iso2code: 'GU',
  },
  JM: {
    callingCode: '1',
    name: {
      en: 'Jamaica',
      de: 'Jamaika',
    },
    code: 'JAM',
    iso2code: 'JM',
  },
  KN: {
    callingCode: '1',
    name: {
      en: 'St. Kitts & Nevis',
      de: 'St. Kitts und Nevis',
    },
    code: 'KNA',
    iso2code: 'KN',
  },
  KY: {
    callingCode: '1',
    name: {
      en: 'Cayman Islands',
      de: 'Kaimaninseln',
    },
    code: 'CYM',
    iso2code: 'KY',
  },
  LC: {
    callingCode: '1',
    name: {
      en: 'St. Lucia',
      de: 'St. Lucia',
    },
    code: 'LCA',
    iso2code: 'LC',
  },
  MP: {
    callingCode: '1',
    name: {
      en: 'Northern Mariana Islands',
      de: 'Nördliche Marianen',
    },
    code: 'MNP',
    iso2code: 'MP',
  },
  MS: {
    callingCode: '1',
    name: {
      en: 'Montserrat',
      de: 'Montserrat',
    },
    code: 'MSR',
    iso2code: 'MS',
  },
  PR: {
    callingCode: '1',
    name: {
      en: 'Puerto Rico',
      de: 'Puerto Rico',
    },
    code: 'PRI',
    iso2code: 'PR',
  },
  TC: {
    callingCode: '1',
    name: {
      en: 'Turks & Caicos Islands',
      de: 'Turks- und Caicosinseln',
    },
    code: 'TCA',
    iso2code: 'TC',
  },
  TT: {
    callingCode: '1',
    name: {
      en: 'Trinidad & Tobago',
      de: 'Trinidad und Tobago',
    },
    code: 'TTO',
    iso2code: 'TT',
  },
  VC: {
    callingCode: '1',
    name: {
      en: 'St. Vincent & Grenadines',
      de: 'St. Vincent und die Grenadinen',
    },
    code: 'VCT',
    iso2code: 'VC',
  },
  VG: {
    callingCode: '1',
    name: {
      en: 'British Virgin Islands',
      de: 'Britische Jungferninseln',
    },
    code: 'VGB',
    iso2code: 'VG',
  },
  VI: {
    callingCode: '1',
    name: {
      en: 'U.S. Virgin Islands',
      de: 'Amerikanische Jungferninseln',
    },
    code: 'VIR',
    iso2code: 'VI',
  },
  RU: {
    callingCode: '7',
    name: {
      en: 'Russia',
      de: 'Russische Föderation',
    },
    code: 'RUS',
    iso2code: 'RU',
  },
  KZ: {
    callingCode: '7',
    name: {
      en: 'Kazakhstan',
      de: 'Kasachstan',
    },
    code: 'KAZ',
    iso2code: 'KZ',
  },
  EG: {
    callingCode: '20',
    name: {
      en: 'Egypt',
      de: 'Ägypten',
    },
    code: 'EGY',
    iso2code: 'EG',
  },
  ZA: {
    callingCode: '27',
    name: {
      en: 'South Africa',
      de: 'Südafrika',
    },
    code: 'ZAF',
    iso2code: 'ZA',
  },
  GR: {
    callingCode: '30',
    name: {
      en: 'Greece',
      de: 'Griechenland',
    },
    code: 'GRC',
    iso2code: 'GR',
  },
  NL: {
    callingCode: '31',
    name: {
      en: 'Netherlands',
      de: 'Niederlande',
    },
    code: 'NLD',
    iso2code: 'NL',
  },
  BE: {
    callingCode: '32',
    name: {
      en: 'Belgium',
      de: 'Belgien',
    },
    code: 'BEL',
    iso2code: 'BE',
  },
  ES: {
    callingCode: '34',
    name: {
      en: 'Spain',
      de: 'Spanien',
    },
    code: 'ESP',
    iso2code: 'ES',
  },
  HU: {
    callingCode: '36',
    name: {
      en: 'Hungary',
      de: 'Ungarn',
    },
    code: 'HUN',
    iso2code: 'HU',
  },
  IT: {
    callingCode: '39',
    name: {
      en: 'Italy',
      de: 'Italien',
    },
    code: 'ITA',
    iso2code: 'IT',
  },
  VA: {
    callingCode: '39',
    name: {
      en: 'Vatican City',
      de: 'Vatikanstadt',
    },
    code: 'VAT',
    iso2code: 'VA',
  },
  RO: {
    callingCode: '40',
    name: {
      en: 'Romania',
      de: 'Rumänien',
    },
    code: 'ROU',
    iso2code: 'RO',
  },
  DK: {
    callingCode: '45',
    name: {
      en: 'Denmark',
      de: 'Dänemark',
    },
    code: 'DNK',
    iso2code: 'DK',
  },
  SE: {
    callingCode: '46',
    name: {
      en: 'Sweden',
      de: 'Schweden',
    },
    code: 'SWE',
    iso2code: 'SE',
  },
  NO: {
    callingCode: '47',
    name: {
      en: 'Norway',
      de: 'Norwegen',
    },
    code: 'NOR',
    iso2code: 'NO',
  },
  PL: {
    callingCode: '48',
    name: {
      en: 'Poland',
      de: 'Polen',
    },
    code: 'POL',
    iso2code: 'PL',
  },
  PE: {
    callingCode: '51',
    name: {
      en: 'Peru',
      de: 'Peru',
    },
    code: 'PER',
    iso2code: 'PE',
  },
  MX: {
    callingCode: '52',
    name: {
      en: 'Mexico',
      de: 'Mexiko',
    },
    code: 'MEX',
    iso2code: 'MX',
  },
  CU: {
    callingCode: '53',
    name: {
      en: 'Cuba',
      de: 'Kuba',
    },
    code: 'CUB',
    iso2code: 'CU',
  },
  AR: {
    callingCode: '54',
    name: {
      en: 'Argentina',
      de: 'Argentinien',
    },
    code: 'ARG',
    iso2code: 'AR',
  },
  BR: {
    callingCode: '55',
    name: {
      en: 'Brazil',
      de: 'Brasilien',
    },
    code: 'BRA',
    iso2code: 'BR',
  },
  CL: {
    callingCode: '56',
    name: {
      en: 'Chile',
      de: 'Chile',
    },
    code: 'CHL',
    iso2code: 'CL',
  },
  CO: {
    callingCode: '57',
    name: {
      en: 'Colombia',
      de: 'Kolumbien',
    },
    code: 'COL',
    iso2code: 'CO',
  },
  VE: {
    callingCode: '58',
    name: {
      en: 'Venezuela',
      de: 'Venezuela',
    },
    code: 'VEN',
    iso2code: 'VE',
  },
  MY: {
    callingCode: '60',
    name: {
      en: 'Malaysia',
      de: 'Malaysia',
    },
    code: 'MYS',
    iso2code: 'MY',
  },
  AU: {
    callingCode: '61',
    name: {
      en: 'Australia',
      de: 'Australien',
    },
    code: 'AUS',
    iso2code: 'AU',
  },
  CC: {
    callingCode: '61',
    name: {
      en: 'Cocos (Keeling) Islands',
      de: 'Kokosinseln',
    },
    code: 'CCK',
    iso2code: 'CC',
  },
  CX: {
    callingCode: '61',
    name: {
      en: 'Christmas Island',
      de: 'Weihnachtsinsel',
    },
    code: 'CXR',
    iso2code: 'CX',
  },
  ID: {
    callingCode: '62',
    name: {
      en: 'Indonesia',
      de: 'Indonesien',
    },
    code: 'IDN',
    iso2code: 'ID',
  },
  PH: {
    callingCode: '63',
    name: {
      en: 'Philippines',
      de: 'Philippinen',
    },
    code: 'PHL',
    iso2code: 'PH',
  },
  NZ: {
    callingCode: '64',
    name: {
      en: 'New Zealand',
      de: 'Neuseeland',
    },
    code: 'NZL',
    iso2code: 'NZ',
  },
  SG: {
    callingCode: '65',
    name: {
      en: 'Singapore',
      de: 'Singapur',
    },
    code: 'SGP',
    iso2code: 'SG',
  },
  TH: {
    callingCode: '66',
    name: {
      en: 'Thailand',
      de: 'Thailand',
    },
    code: 'THA',
    iso2code: 'TH',
  },
  JP: {
    callingCode: '81',
    name: {
      en: 'Japan',
      de: 'Japan',
    },
    code: 'JPN',
    iso2code: 'JP',
  },
  KR: {
    callingCode: '82',
    name: {
      en: 'South Korea',
      de: 'Republik Korea',
    },
    code: 'KOR',
    iso2code: 'KR',
  },
  VN: {
    callingCode: '84',
    name: {
      en: 'Vietnam',
      de: 'Vietnam',
    },
    code: 'VNM',
    iso2code: 'VN',
  },
  CN: {
    callingCode: '86',
    name: {
      en: 'China',
      de: 'China',
    },
    code: 'CHN',
    iso2code: 'CN',
  },
  TR: {
    callingCode: '90',
    name: {
      en: 'Turkey',
      de: 'Türkei',
    },
    code: 'TUR',
    iso2code: 'TR',
  },
  IN: {
    callingCode: '91',
    name: {
      en: 'India',
      de: 'Indien',
    },
    code: 'IND',
    iso2code: 'IN',
  },
  PK: {
    callingCode: '92',
    name: {
      en: 'Pakistan',
      de: 'Pakistan',
    },
    code: 'PAK',
    iso2code: 'PK',
  },
  AF: {
    callingCode: '93',
    name: {
      en: 'Afghanistan',
      de: 'Afghanistan',
    },
    code: 'AFG',
    iso2code: 'AF',
  },
  LK: {
    callingCode: '94',
    name: {
      en: 'Sri Lanka',
      de: 'Sri Lanka',
    },
    code: 'LKA',
    iso2code: 'LK',
  },
  MM: {
    callingCode: '95',
    name: {
      en: 'Myanmar (Burma)',
      de: 'Myanmar',
    },
    code: 'MMR',
    iso2code: 'MM',
  },
  IR: {
    callingCode: '98',
    name: {
      en: 'Iran',
      de: 'Iran',
    },
    code: 'IRN',
    iso2code: 'IR',
  },
  SS: {
    callingCode: '211',
    name: {
      en: 'South Sudan',
      de: 'Südsudan',
    },
    code: 'SSD',
    iso2code: 'SS',
  },
  MA: {
    callingCode: '212',
    name: {
      en: 'Morocco',
      de: 'Marokko',
    },
    code: 'MAR',
    iso2code: 'MA',
  },
  DZ: {
    callingCode: '213',
    name: {
      en: 'Algeria',
      de: 'Algerien',
    },
    code: 'DZA',
    iso2code: 'DZ',
  },
  TN: {
    callingCode: '216',
    name: {
      en: 'Tunisia',
      de: 'Tunesien',
    },
    code: 'TUN',
    iso2code: 'TN',
  },
  LY: {
    callingCode: '218',
    name: {
      en: 'Libya',
      de: 'Libyen',
    },
    code: 'LBY',
    iso2code: 'LY',
  },
  GM: {
    callingCode: '220',
    name: {
      en: 'Gambia',
      de: 'Gambia',
    },
    code: 'GMB',
    iso2code: 'GM',
  },
  SN: {
    callingCode: '221',
    name: {
      en: 'Senegal',
      de: 'Senegal',
    },
    code: 'SEN',
    iso2code: 'SN',
  },
  MR: {
    callingCode: '222',
    name: {
      en: 'Mauritania',
      de: 'Mauretanien',
    },
    code: 'MRT',
    iso2code: 'MR',
  },
  ML: {
    callingCode: '223',
    name: {
      en: 'Mali',
      de: 'Mali',
    },
    code: 'MLI',
    iso2code: 'ML',
  },
  GN: {
    callingCode: '224',
    name: {
      en: 'Guinea',
      de: 'Guinea',
    },
    code: 'GIN',
    iso2code: 'GN',
  },
  CI: {
    callingCode: '225',
    name: {
      en: 'Côte d’Ivoire',
      de: 'Côte d’Ivoire',
    },
    code: 'CIV',
    iso2code: 'CI',
  },
  BF: {
    callingCode: '226',
    name: {
      en: 'Burkina Faso',
      de: 'Burkina Faso',
    },
    code: 'BFA',
    iso2code: 'BF',
  },
  NE: {
    callingCode: '227',
    name: {
      en: 'Niger',
      de: 'Niger',
    },
    code: 'NER',
    iso2code: 'NE',
  },
  TG: {
    callingCode: '228',
    name: {
      en: 'Togo',
      de: 'Togo',
    },
    code: 'TGO',
    iso2code: 'TG',
  },
  BJ: {
    callingCode: '229',
    name: {
      en: 'Benin',
      de: 'Benin',
    },
    code: 'BEN',
    iso2code: 'BJ',
  },
  MU: {
    callingCode: '230',
    name: {
      en: 'Mauritius',
      de: 'Mauritius',
    },
    code: 'MUS',
    iso2code: 'MU',
  },
  LR: {
    callingCode: '231',
    name: {
      en: 'Liberia',
      de: 'Liberia',
    },
    code: 'LBR',
    iso2code: 'LR',
  },
  SL: {
    callingCode: '232',
    name: {
      en: 'Sierra Leone',
      de: 'Sierra Leone',
    },
    code: 'SLE',
    iso2code: 'SL',
  },
  GH: {
    callingCode: '233',
    name: {
      en: 'Ghana',
      de: 'Ghana',
    },
    code: 'GHA',
    iso2code: 'GH',
  },
  NG: {
    callingCode: '234',
    name: {
      en: 'Nigeria',
      de: 'Nigeria',
    },
    code: 'NGA',
    iso2code: 'NG',
  },
  TD: {
    callingCode: '235',
    name: {
      en: 'Chad',
      de: 'Tschad',
    },
    code: 'TCD',
    iso2code: 'TD',
  },
  CF: {
    callingCode: '236',
    name: {
      en: 'Central African Republic',
      de: 'Zentralafrikanische Republik',
    },
    code: 'CAF',
    iso2code: 'CF',
  },
  CM: {
    callingCode: '237',
    name: {
      en: 'Cameroon',
      de: 'Kamerun',
    },
    code: 'CMR',
    iso2code: 'CM',
  },
  CV: {
    callingCode: '238',
    name: {
      en: 'Cape Verde',
      de: 'Kap Verde',
    },
    code: 'CPV',
    iso2code: 'CV',
  },
  ST: {
    callingCode: '239',
    name: {
      en: 'São Tomé & Príncipe',
      de: 'São Tomé und Príncipe',
    },
    code: 'STP',
    iso2code: 'ST',
  },
  GQ: {
    callingCode: '240',
    name: {
      en: 'Equatorial Guinea',
      de: 'Äquatorialguinea',
    },
    code: 'GNQ',
    iso2code: 'GQ',
  },
  GA: {
    callingCode: '241',
    name: {
      en: 'Gabon',
      de: 'Gabun',
    },
    code: 'GAB',
    iso2code: 'GA',
  },
  CG: {
    callingCode: '242',
    name: {
      en: 'Congo - Brazzaville',
      de: 'Kongo-Brazzaville',
    },
    code: 'COG',
    iso2code: 'CG',
  },
  CD: {
    callingCode: '243',
    name: {
      en: 'Congo - Kinshasa',
      de: 'Kongo-Kinshasa',
    },
    code: 'COD',
    iso2code: 'CD',
  },
  AO: {
    callingCode: '244',
    name: {
      en: 'Angola',
      de: 'Angola',
    },
    code: 'AGO',
    iso2code: 'AO',
  },
  GW: {
    callingCode: '245',
    name: {
      en: 'Guinea-Bissau',
      de: 'Guinea-Bissau',
    },
    code: 'GNB',
    iso2code: 'GW',
  },
  IO: {
    callingCode: '246',
    name: {
      en: 'British Indian Ocean Territory',
      de: 'Britisches Territorium im Indischen Ozean',
    },
    code: 'IOT',
    iso2code: 'IO',
  },
  SC: {
    callingCode: '248',
    name: {
      en: 'Seychelles',
      de: 'Seychellen',
    },
    code: 'SYC',
    iso2code: 'SC',
  },
  SD: {
    callingCode: '249',
    name: {
      en: 'Sudan',
      de: 'Sudan',
    },
    code: 'SDN',
    iso2code: 'SD',
  },
  RW: {
    callingCode: '250',
    name: {
      en: 'Rwanda',
      de: 'Ruanda',
    },
    code: 'RWA',
    iso2code: 'RW',
  },
  ET: {
    callingCode: '251',
    name: {
      en: 'Ethiopia',
      de: 'Äthiopien',
    },
    code: 'ETH',
    iso2code: 'ET',
  },
  SO: {
    callingCode: '252',
    name: {
      en: 'Somalia',
      de: 'Somalia',
    },
    code: 'SOM',
    iso2code: 'SO',
  },
  DJ: {
    callingCode: '253',
    name: {
      en: 'Djibouti',
      de: 'Dschibuti',
    },
    code: 'DJI',
    iso2code: 'DJ',
  },
  KE: {
    callingCode: '254',
    name: {
      en: 'Kenya',
      de: 'Kenia',
    },
    code: 'KEN',
    iso2code: 'KE',
  },
  TZ: {
    callingCode: '255',
    name: {
      en: 'Tanzania',
      de: 'Tansania',
    },
    code: 'TZA',
    iso2code: 'TZ',
  },
  UG: {
    callingCode: '256',
    name: {
      en: 'Uganda',
      de: 'Uganda',
    },
    code: 'UGA',
    iso2code: 'UG',
  },
  BI: {
    callingCode: '257',
    name: {
      en: 'Burundi',
      de: 'Burundi',
    },
    code: 'BDI',
    iso2code: 'BI',
  },
  MZ: {
    callingCode: '258',
    name: {
      en: 'Mozambique',
      de: 'Mosambik',
    },
    code: 'MOZ',
    iso2code: 'MZ',
  },
  ZM: {
    callingCode: '260',
    name: {
      en: 'Zambia',
      de: 'Sambia',
    },
    code: 'ZMB',
    iso2code: 'ZM',
  },
  MG: {
    callingCode: '261',
    name: {
      en: 'Madagascar',
      de: 'Madagaskar',
    },
    code: 'MDG',
    iso2code: 'MG',
  },
  YT: {
    callingCode: '262',
    name: {
      en: 'Mayotte',
      de: 'Mayotte',
    },
    code: 'MYT',
    iso2code: 'YT',
  },
  ZW: {
    callingCode: '263',
    name: {
      en: 'Zimbabwe',
      de: 'Simbabwe',
    },
    code: 'ZWE',
    iso2code: 'ZW',
  },
  NA: {
    callingCode: '264',
    name: {
      en: 'Namibia',
      de: 'Namibia',
    },
    code: 'NAM',
    iso2code: 'NA',
  },
  MW: {
    callingCode: '265',
    name: {
      en: 'Malawi',
      de: 'Malawi',
    },
    code: 'MWI',
    iso2code: 'MW',
  },
  LS: {
    callingCode: '266',
    name: {
      en: 'Lesotho',
      de: 'Lesotho',
    },
    code: 'LSO',
    iso2code: 'LS',
  },
  BW: {
    callingCode: '267',
    name: {
      en: 'Botswana',
      de: 'Botsuana',
    },
    code: 'BWA',
    iso2code: 'BW',
  },
  SZ: {
    callingCode: '268',
    name: {
      en: 'Eswatini',
      de: 'Eswatini',
    },
    code: 'SWZ',
    iso2code: 'SZ',
  },
  KM: {
    callingCode: '269',
    name: {
      en: 'Comoros',
      de: 'Komoren',
    },
    code: 'COM',
    iso2code: 'KM',
  },
  SH: {
    callingCode: '290',
    name: {
      en: 'St. Helena',
      de: 'St. Helena',
    },
    code: 'SHN',
    iso2code: 'SH',
  },
  ER: {
    callingCode: '291',
    name: {
      en: 'Eritrea',
      de: 'Eritrea',
    },
    code: 'ERI',
    iso2code: 'ER',
  },
  AW: {
    callingCode: '297',
    name: {
      en: 'Aruba',
      de: 'Aruba',
    },
    code: 'ABW',
    iso2code: 'AW',
  },
  FO: {
    callingCode: '298',
    name: {
      en: 'Faroe Islands',
      de: 'Färöer',
    },
    code: 'FRO',
    iso2code: 'FO',
  },
  GL: {
    callingCode: '299',
    name: {
      en: 'Greenland',
      de: 'Grönland',
    },
    code: 'GRL',
    iso2code: 'GL',
  },
  GI: {
    callingCode: '350',
    name: {
      en: 'Gibraltar',
      de: 'Gibraltar',
    },
    code: 'GIB',
    iso2code: 'GI',
  },
  PT: {
    callingCode: '351',
    name: {
      en: 'Portugal',
      de: 'Portugal',
    },
    code: 'PRT',
    iso2code: 'PT',
  },
  LU: {
    callingCode: '352',
    name: {
      en: 'Luxembourg',
      de: 'Luxemburg',
    },
    code: 'LUX',
    iso2code: 'LU',
  },
  IE: {
    callingCode: '353',
    name: {
      en: 'Ireland',
      de: 'Irland',
    },
    code: 'IRL',
    iso2code: 'IE',
  },
  IS: {
    callingCode: '354',
    name: {
      en: 'Iceland',
      de: 'Island',
    },
    code: 'ISL',
    iso2code: 'IS',
  },
  AL: {
    callingCode: '355',
    name: {
      en: 'Albania',
      de: 'Albanien',
    },
    code: 'ALB',
    iso2code: 'AL',
  },
  MT: {
    callingCode: '356',
    name: {
      en: 'Malta',
      de: 'Malta',
    },
    code: 'MLT',
    iso2code: 'MT',
  },
  CY: {
    callingCode: '357',
    name: {
      en: 'Cyprus',
      de: 'Zypern',
    },
    code: 'CYP',
    iso2code: 'CY',
  },
  FI: {
    callingCode: '358',
    name: {
      en: 'Finland',
      de: 'Finnland',
    },
    code: 'FIN',
    iso2code: 'FI',
  },
  LT: {
    callingCode: '370',
    name: {
      en: 'Lithuania',
      de: 'Litauen',
    },
    code: 'LTU',
    iso2code: 'LT',
  },
  LV: {
    callingCode: '371',
    name: {
      en: 'Latvia',
      de: 'Lettland',
    },
    code: 'LVA',
    iso2code: 'LV',
  },
  EE: {
    callingCode: '372',
    name: {
      en: 'Estonia',
      de: 'Estland',
    },
    code: 'EST',
    iso2code: 'EE',
  },
  MD: {
    callingCode: '373',
    name: {
      en: 'Moldova',
      de: 'Republik Moldau',
    },
    code: 'MDA',
    iso2code: 'MD',
  },
  AM: {
    callingCode: '374',
    name: {
      en: 'Armenia',
      de: 'Armenien',
    },
    code: 'ARM',
    iso2code: 'AM',
  },
  BY: {
    callingCode: '375',
    name: {
      en: 'Belarus',
      de: 'Belarus',
    },
    code: 'BLR',
    iso2code: 'BY',
  },
  AD: {
    callingCode: '376',
    name: {
      en: 'Andorra',
      de: 'Andorra',
    },
    code: 'AND',
    iso2code: 'AD',
  },
  MC: {
    callingCode: '377',
    name: {
      en: 'Monaco',
      de: 'Monaco',
    },
    code: 'MCO',
    iso2code: 'MC',
  },
  SM: {
    callingCode: '378',
    name: {
      en: 'San Marino',
      de: 'San Marino',
    },
    code: 'SMR',
    iso2code: 'SM',
  },
  UA: {
    callingCode: '380',
    name: {
      en: 'Ukraine',
      de: 'Ukraine',
    },
    code: 'UKR',
    iso2code: 'UA',
  },
  RS: {
    callingCode: '381',
    name: {
      en: 'Serbia',
      de: 'Serbien',
    },
    code: 'SRB',
    iso2code: 'RS',
  },
  ME: {
    callingCode: '382',
    name: {
      en: 'Montenegro',
      de: 'Montenegro',
    },
    code: 'MNE',
    iso2code: 'ME',
  },
  XK: {
    callingCode: '383',
    name: {
      en: 'Kosovo',
      de: 'Kosovo',
    },
    code: 'XKX',
    iso2code: 'XK',
  },
  HR: {
    callingCode: '385',
    name: {
      en: 'Croatia',
      de: 'Kroatien',
    },
    code: 'HRV',
    iso2code: 'HR',
  },
  SI: {
    callingCode: '386',
    name: {
      en: 'Slovenia',
      de: 'Slowenien',
    },
    code: 'SVN',
    iso2code: 'SI',
  },
  BA: {
    callingCode: '387',
    name: {
      en: 'Bosnia & Herzegovina',
      de: 'Bosnien und Herzegowina',
    },
    code: 'BIH',
    iso2code: 'BA',
  },
  MK: {
    callingCode: '389',
    name: {
      en: 'North Macedonia',
      de: 'Nordmazedonien',
    },
    code: 'MKD',
    iso2code: 'MK',
  },
  CZ: {
    callingCode: '420',
    name: {
      en: 'Czech Republic',
      de: 'Tschechische Republik',
    },
    code: 'CZE',
    iso2code: 'CZ',
  },
  SK: {
    callingCode: '421',
    name: {
      en: 'Slovakia',
      de: 'Slowakei',
    },
    code: 'SVK',
    iso2code: 'SK',
  },
  LI: {
    callingCode: '423',
    name: {
      en: 'Liechtenstein',
      de: 'Liechtenstein',
    },
    code: 'LIE',
    iso2code: 'LI',
  },
  FK: {
    callingCode: '500',
    name: {
      en: 'Falkland Islands',
      de: 'Falklandinseln',
    },
    code: 'FLK',
    iso2code: 'FK',
  },
  BZ: {
    callingCode: '501',
    name: {
      en: 'Belize',
      de: 'Belize',
    },
    code: 'BLZ',
    iso2code: 'BZ',
  },
  GT: {
    callingCode: '502',
    name: {
      en: 'Guatemala',
      de: 'Guatemala',
    },
    code: 'GTM',
    iso2code: 'GT',
  },
  SV: {
    callingCode: '503',
    name: {
      en: 'El Salvador',
      de: 'El Salvador',
    },
    code: 'SLV',
    iso2code: 'SV',
  },
  HN: {
    callingCode: '504',
    name: {
      en: 'Honduras',
      de: 'Honduras',
    },
    code: 'HND',
    iso2code: 'HN',
  },
  NI: {
    callingCode: '505',
    name: {
      en: 'Nicaragua',
      de: 'Nicaragua',
    },
    code: 'NIC',
    iso2code: 'NI',
  },
  CR: {
    callingCode: '506',
    name: {
      en: 'Costa Rica',
      de: 'Costa Rica',
    },
    code: 'CRI',
    iso2code: 'CR',
  },
  PA: {
    callingCode: '507',
    name: {
      en: 'Panama',
      de: 'Panama',
    },
    code: 'PAN',
    iso2code: 'PA',
  },
  PM: {
    callingCode: '508',
    name: {
      en: 'St. Pierre & Miquelon',
      de: 'St. Pierre und Miquelon',
    },
    code: 'SPM',
    iso2code: 'PM',
  },
  HT: {
    callingCode: '509',
    name: {
      en: 'Haiti',
      de: 'Haiti',
    },
    code: 'HTI',
    iso2code: 'HT',
  },
  BO: {
    callingCode: '591',
    name: {
      en: 'Bolivia',
      de: 'Bolivien',
    },
    code: 'BOL',
    iso2code: 'BO',
  },
  GY: {
    callingCode: '592',
    name: {
      en: 'Guyana',
      de: 'Guyana',
    },
    code: 'GUY',
    iso2code: 'GY',
  },
  EC: {
    callingCode: '593',
    name: {
      en: 'Ecuador',
      de: 'Ecuador',
    },
    code: 'ECU',
    iso2code: 'EC',
  },
  PY: {
    callingCode: '595',
    name: {
      en: 'Paraguay',
      de: 'Paraguay',
    },
    code: 'PRY',
    iso2code: 'PY',
  },
  SR: {
    callingCode: '597',
    name: {
      en: 'Suriname',
      de: 'Suriname',
    },
    code: 'SUR',
    iso2code: 'SR',
  },
  UY: {
    callingCode: '598',
    name: {
      en: 'Uruguay',
      de: 'Uruguay',
    },
    code: 'URY',
    iso2code: 'UY',
  },
  TL: {
    callingCode: '670',
    name: {
      en: 'Timor-Leste',
      de: 'Timor-Leste',
    },
    code: 'TLS',
    iso2code: 'TL',
  },
  NF: {
    callingCode: '672',
    name: {
      en: 'Norfolk Island',
      de: 'Norfolkinsel',
    },
    code: 'NFK',
    iso2code: 'NF',
  },
  BN: {
    callingCode: '673',
    name: {
      en: 'Brunei',
      de: 'Brunei Darussalam',
    },
    code: 'BRN',
    iso2code: 'BN',
  },
  NR: {
    callingCode: '674',
    name: {
      en: 'Nauru',
      de: 'Nauru',
    },
    code: 'NRU',
    iso2code: 'NR',
  },
  PG: {
    callingCode: '675',
    name: {
      en: 'Papua New Guinea',
      de: 'Papua-Neuguinea',
    },
    code: 'PNG',
    iso2code: 'PG',
  },
  TO: {
    callingCode: '676',
    name: {
      en: 'Tonga',
      de: 'Tonga',
    },
    code: 'TON',
    iso2code: 'TO',
  },
  SB: {
    callingCode: '677',
    name: {
      en: 'Solomon Islands',
      de: 'Salomonen',
    },
    code: 'SLB',
    iso2code: 'SB',
  },
  VU: {
    callingCode: '678',
    name: {
      en: 'Vanuatu',
      de: 'Vanuatu',
    },
    code: 'VUT',
    iso2code: 'VU',
  },
  FJ: {
    callingCode: '679',
    name: {
      en: 'Fiji',
      de: 'Fidschi',
    },
    code: 'FJI',
    iso2code: 'FJ',
  },
  PW: {
    callingCode: '680',
    name: {
      en: 'Palau',
      de: 'Palau',
    },
    code: 'PLW',
    iso2code: 'PW',
  },
  WF: {
    callingCode: '681',
    name: {
      en: 'Wallis & Futuna',
      de: 'Wallis und Futuna',
    },
    code: 'WLF',
    iso2code: 'WF',
  },
  CK: {
    callingCode: '682',
    name: {
      en: 'Cook Islands',
      de: 'Cookinseln',
    },
    code: 'COK',
    iso2code: 'CK',
  },
  NU: {
    callingCode: '683',
    name: {
      en: 'Niue',
      de: 'Niue',
    },
    code: 'NIU',
    iso2code: 'NU',
  },
  WS: {
    callingCode: '685',
    name: {
      en: 'Samoa',
      de: 'Samoa',
    },
    code: 'WSM',
    iso2code: 'WS',
  },
  KI: {
    callingCode: '686',
    name: {
      en: 'Kiribati',
      de: 'Kiribati',
    },
    code: 'KIR',
    iso2code: 'KI',
  },
  NC: {
    callingCode: '687',
    name: {
      en: 'New Caledonia',
      de: 'Neukaledonien',
    },
    code: 'NCL',
    iso2code: 'NC',
  },
  TV: {
    callingCode: '688',
    name: {
      en: 'Tuvalu',
      de: 'Tuvalu',
    },
    code: 'TUV',
    iso2code: 'TV',
  },
  PF: {
    callingCode: '689',
    name: {
      en: 'French Polynesia',
      de: 'Französisch-Polynesien',
    },
    code: 'PYF',
    iso2code: 'PF',
  },
  TK: {
    callingCode: '690',
    name: {
      en: 'Tokelau',
      de: 'Tokelau',
    },
    code: 'TKL',
    iso2code: 'TK',
  },
  FM: {
    callingCode: '691',
    name: {
      en: 'Micronesia',
      de: 'Mikronesien',
    },
    code: 'FSM',
    iso2code: 'FM',
  },
  MH: {
    callingCode: '692',
    name: {
      en: 'Marshall Islands',
      de: 'Marshallinseln',
    },
    code: 'MHL',
    iso2code: 'MH',
  },
  KP: {
    callingCode: '850',
    name: {
      en: 'North Korea',
      de: 'Demokratische Volksrepublik Korea',
    },
    code: 'PRK',
    iso2code: 'KP',
  },
  HK: {
    callingCode: '852',
    name: {
      en: 'Hong Kong SAR China',
      de: 'Sonderverwaltungszone Hongkong',
    },
    code: 'HKG',
    iso2code: 'HK',
  },
  MO: {
    callingCode: '853',
    name: {
      en: 'Macau SAR China',
      de: 'Sonderverwaltungsregion Macau',
    },
    code: 'MAC',
    iso2code: 'MO',
  },
  KH: {
    callingCode: '855',
    name: {
      en: 'Cambodia',
      de: 'Kambodscha',
    },
    code: 'KHM',
    iso2code: 'KH',
  },
  LA: {
    callingCode: '856',
    name: {
      en: 'Laos',
      de: 'Laos',
    },
    code: 'LAO',
    iso2code: 'LA',
  },
  BD: {
    callingCode: '880',
    name: {
      en: 'Bangladesh',
      de: 'Bangladesch',
    },
    code: 'BGD',
    iso2code: 'BD',
  },
  TW: {
    callingCode: '886',
    name: {
      en: 'Taiwan',
      de: 'Taiwan',
    },
    code: 'TWN',
    iso2code: 'TW',
  },
  MV: {
    callingCode: '960',
    name: {
      en: 'Maldives',
      de: 'Malediven',
    },
    code: 'MDV',
    iso2code: 'MV',
  },
  LB: {
    callingCode: '961',
    name: {
      en: 'Lebanon',
      de: 'Libanon',
    },
    code: 'LBN',
    iso2code: 'LB',
  },
  JO: {
    callingCode: '962',
    name: {
      en: 'Jordan',
      de: 'Jordanien',
    },
    code: 'JOR',
    iso2code: 'JO',
  },
  SY: {
    callingCode: '963',
    name: {
      en: 'Syria',
      de: 'Syrien',
    },
    code: 'SYR',
    iso2code: 'SY',
  },
  IQ: {
    callingCode: '964',
    name: {
      en: 'Iraq',
      de: 'Irak',
    },
    code: 'IRQ',
    iso2code: 'IQ',
  },
  KW: {
    callingCode: '965',
    name: {
      en: 'Kuwait',
      de: 'Kuwait',
    },
    code: 'KWT',
    iso2code: 'KW',
  },
  SA: {
    callingCode: '966',
    name: {
      en: 'Saudi Arabia',
      de: 'Saudi-Arabien',
    },
    code: 'SAU',
    iso2code: 'SA',
  },
  YE: {
    callingCode: '967',
    name: {
      en: 'Yemen',
      de: 'Jemen',
    },
    code: 'YEM',
    iso2code: 'YE',
  },
  OM: {
    callingCode: '968',
    name: {
      en: 'Oman',
      de: 'Oman',
    },
    code: 'OMN',
    iso2code: 'OM',
  },
  PS: {
    callingCode: '970',
    name: {
      en: 'Palestinian Territories',
      de: 'Palästinensische Autonomiegebiete',
    },
    code: 'PSE',
    iso2code: 'PS',
  },
  AE: {
    callingCode: '971',
    name: {
      en: 'United Arab Emirates',
      de: 'Vereinigte Arabische Emirate',
    },
    code: 'ARE',
    iso2code: 'AE',
  },
  IL: {
    callingCode: '972',
    name: {
      en: 'Israel',
      de: 'Israel',
    },
    code: 'ISR',
    iso2code: 'IL',
  },
  BH: {
    callingCode: '973',
    name: {
      en: 'Bahrain',
      de: 'Bahrain',
    },
    code: 'BHR',
    iso2code: 'BH',
  },
  QA: {
    callingCode: '974',
    name: {
      en: 'Qatar',
      de: 'Katar',
    },
    code: 'QAT',
    iso2code: 'QA',
  },
  BT: {
    callingCode: '975',
    name: {
      en: 'Bhutan',
      de: 'Bhutan',
    },
    code: 'BTN',
    iso2code: 'BT',
  },
  MN: {
    callingCode: '976',
    name: {
      en: 'Mongolia',
      de: 'Mongolei',
    },
    code: 'MNG',
    iso2code: 'MN',
  },
  NP: {
    callingCode: '977',
    name: {
      en: 'Nepal',
      de: 'Nepal',
    },
    code: 'NPL',
    iso2code: 'NP',
  },
  TJ: {
    callingCode: '992',
    name: {
      en: 'Tajikistan',
      de: 'Tadschikistan',
    },
    code: 'TJK',
    iso2code: 'TJ',
  },
  TM: {
    callingCode: '993',
    name: {
      en: 'Turkmenistan',
      de: 'Turkmenistan',
    },
    code: 'TKM',
    iso2code: 'TM',
  },
  AZ: {
    callingCode: '994',
    name: {
      en: 'Azerbaijan',
      de: 'Aserbaidschan',
    },
    code: 'AZE',
    iso2code: 'AZ',
  },
  GE: {
    callingCode: '995',
    name: {
      en: 'Georgia',
      de: 'Georgien',
    },
    code: 'GEO',
    iso2code: 'GE',
  },
  KG: {
    callingCode: '996',
    name: {
      en: 'Kyrgyzstan',
      de: 'Kirgisistan',
    },
    code: 'KGZ',
    iso2code: 'KG',
  },
  UZ: {
    callingCode: '998',
    name: {
      en: 'Uzbekistan',
      de: 'Usbekistan',
    },
    code: 'UZB',
    iso2code: 'UZ',
  },
}

const topCountries = ['DE', 'CH', 'AT', 'GB', 'BE', 'LU', 'NL', 'LI', 'CZ', 'PL', 'BG']
const removeAccents = (value: string) => value.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

const sortMetadata = (language: SupportedLocale) =>
  Object.values(metadataByCountryCode)
    .sort((countryA, countryB) => {
      if (language === 'cimode') {
        return 0
      }

      if (removeAccents(countryA.name[language]) > removeAccents(countryB.name[language])) {
        return 1
      }

      if (removeAccents(countryA.name[language]) < removeAccents(countryB.name[language])) {
        return -1
      }

      return 0
    })
    .filter((country) => !topCountries.includes(country.iso2code))

export const getPhoneMetadata = (language: SupportedLocale) => {
  return [
    ...topCountries.map((code) => metadataByCountryCode[code as keyof typeof metadataByCountryCode]),
    ...sortMetadata(language),
  ]
}
