import React from 'react'

import { colors } from '@dentalux/ui-library-core/cjs/constants/colors/colors'

import { SvgIcon, SvgIconProps } from '@mui/material'

const Person = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24" fill="none" sx={{ width: '24px', height: '24px', ...props.sx }}>
      <g clipPath="url(#clip0_9820_1229)">
        <path
          d="M12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12ZM12 14C9.33 14 4 15.34 4 18V20H20V18C20 15.34 14.67 14 12 14Z"
          fill={colors.grey[800]}
        />
      </g>
      <defs>
        <clipPath id="clip0_9820_1229">
          <rect width="24" height="24" fill={colors.grey[0]} />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}

export default Person
