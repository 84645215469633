import { FilledInput, inputAdornmentClasses, inputBaseClasses } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Input = styled(FilledInput)(({ theme }) => ({
  padding: theme.spacing(0, 2),
  background: theme.palette.common.white,
  fontSize: theme.typography['Lato Body 1'].fontSize + '!important',
  fontWeight: theme.typography['Lato Body 1'].fontWeight,
  fontFamily: theme.typography['Lato Body 1'].fontFamily,
  lineHeight: theme.typography['Lato Body 1'].lineHeight,

  [`input:-webkit-autofill,
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active`]: {
    boxShadow: '0 0 0 30px white inset',
  },

  '&.Mui-disabled': {
    opacity: 0.38,
    background: 'transparent',

    '&:hover': {
      background: 'transparent',
    },

    '&:before': {
      borderBottomStyle: 'solid',
    },
  },

  [`& .${inputBaseClasses.input}`]: {
    padding: theme.spacing(3, 0, 1, 0),
  },

  [`& .${inputAdornmentClasses.positionStart}`]: {
    marginRight: theme.spacing(2),
  },

  ['&:hover']: {
    background: theme.palette.grey[100],

    ['& input:-webkit-autofill']: {
      boxShadow: `0 0 0 30px ${theme.palette.grey[100]} inset`,
    },
  },

  ['&.Mui-focused:not(:hover)']: {
    background: theme.palette.common.white,
  },

  ['&.Mui-error:after']: {
    borderBottomColor: theme.palette.error[800],
  },
}))
