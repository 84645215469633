import { autocompleteClasses, Box, filledInputClasses, inputLabelClasses, paperClasses, Popper } from '@mui/material'
import { styled } from '@mui/material/styles'

export const DropDownContent = styled('div')(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: 'auto',
  top: 56,
  zIndex: 99,
  overflowX: 'hidden',
  boxShadow: '0px 1px 2px rgb(0 0 0 / 30%), 0px 1px 3px 1px rgb(0 0 0 / 15%)',
  backgroundColor: theme.palette.common.white,

  [`.${inputLabelClasses.root}`]: {
    paddingLeft: theme.spacing(4),
  },

  [`.${filledInputClasses.root}.${autocompleteClasses.inputRoot}`]: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(0, 0, 0, 1.5),

    [`.${filledInputClasses.input}`]: {
      padding: theme.spacing(3.1, 1.5, 1, 1),
    },
  },
}))

export const CountryPopper = styled(Popper)(({ theme }) => ({
  [`.${paperClasses.root}`]: {
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
  },
  [`.${autocompleteClasses.listbox}`]: {
    padding: '0',
    borderRadius: 0,
    height: 168,
    boxShadow: 'none',
  },
  [`li.${autocompleteClasses.option}`]: {
    backgroundColor: theme.palette.common.white,
  },
}))

export const CountryListItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  padding: theme.spacing(2, 3, 2, 2) + '!important',
  height: 56,
  color: theme.palette.grey[800],
}))

export const FlagTrigger = styled('div')(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(0, 1.5, 0, 0),
  cursor: 'pointer',
  svg: {
    position: 'absolute',
    top: -3,
    right: -1,
    padding: 0,
    margin: 0,
    color: 'rgba(0, 0, 0, 0.54)',
    width: 15,
  },
}))

export const CallingCode = styled('span')(({ theme }) => ({
  color: theme.palette.grey[900],
  paddingTop: theme.spacing(2.25),
}))

export const Image = styled('img')(({ width = 18, height = 18 }) => ({
  width,
  height,
  objectFit: 'cover',
  borderRadius: '50%',
  border: '1px solid #FFF',
}))

export const Country = styled('span')(({ theme }) => ({
  paddingLeft: theme.spacing(1.625),
  ...theme.typography['Lato Emphasized 2'],
}))
