import React from 'react'

import { useTranslation } from 'next-i18next'

import { Box } from '@mui/material'

import clsx from 'clsx'
import { DataTestIds } from 'src/config/dataTestIds'

import { calculateScore, PasswordStrengthScore } from './PasswordStrength.helpers'
import * as S from './PasswordStrength.styles'

type Props = {
  value: string
}

const indications = {
  0: [0, 0, 0],
  1: [1, 0, 0],
  2: [1, 1, 0],
  3: [1, 1, 1],
}

const PasswordStrength = ({ value }: Props) => {
  const { t } = useTranslation()

  const score = calculateScore(value)

  const strengthIndicators = indications[score] || indications[0]

  const strength = PasswordStrengthScore[score] as keyof typeof PasswordStrengthScore

  const label = score > 0 ? (strength || '').toLowerCase() : ''

  return (
    <S.Wrapper data-testid={DataTestIds.StrengthIndicator} data-strength={strength} strength={strength}>
      <Box display="flex" alignItems="center">
        {strengthIndicators.map((item, index) => (
          <S.Indicator key={`strength-indicator-${item}-${index}`} className={clsx('indicator', { indicate: item })} />
        ))}
        <S.Label className="label" variant="Lato Caption Small Bold">
          {label && t(`b2c.strengthIndicator.${label}`)}
        </S.Label>
      </Box>
      <S.Hint variant="Lato Caption">{t('b2c.passwordStrengthHint')}</S.Hint>
    </S.Wrapper>
  )
}

export default PasswordStrength
