import BaseInputLabel from '@mui/material/InputLabel'
import { styled } from '@mui/material/styles'

const InputLabel = styled(BaseInputLabel, {
  shouldForwardProp: (prop) => prop !== 'labelOffset',
})<{ labelOffset?: number }>(({ theme, labelOffset = 0 }) => ({
  left: theme.spacing(2 + labelOffset / 8),
}))

export default InputLabel
