import { FormControlLabel as BaseFormControlLabel, typographyClasses } from '@mui/material'
import { styled } from '@mui/material/styles'

export const FormControlLabel = styled(BaseFormControlLabel, {
  shouldForwardProp: (prop: string) => prop !== 'align',
})<{ align?: 'top' | 'center' }>(({ theme, align }) => ({
  alignItems: align === 'top' ? 'flex-start' : 'center',
  ...theme.typography['Lato Body 1'],

  [`.${typographyClasses.root}`]: {
    paddingTop: theme.spacing(align === 'top' ? 1.125 : 0),
    marginLeft: theme.spacing(0.5),

    a: {
      color: theme.palette.primary[800],
      textDecoration: 'none',
    },
  },
}))

FormControlLabel.defaultProps = {
  required: false,
}
