import React from 'react'

import { colors } from '@dentalux/ui-library-core/cjs/constants/colors/colors'

import { SvgIcon, SvgIconProps } from '@mui/material'

const Visibility = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 22 15" sx={{ width: 22, height: 15 }}>
      <path
        d="M11 0C4.45992 0 0.235391 6.75487 0.158984 6.87786C0.0561392 7.00741 0.00011119 7.16793 0 7.33333C0.000130096 7.47523 0.041426 7.61405 0.11888 7.73294C0.11983 7.73438 0.120785 7.73581 0.121745 7.73724C0.134353 7.76326 3.68048 14.6667 11 14.6667C18.2885 14.6667 21.8294 7.83108 21.8725 7.74727C21.8754 7.74252 21.8783 7.73775 21.8811 7.73294C21.9586 7.61405 21.9999 7.47523 22 7.33333C22 7.16859 21.9445 7.00865 21.8424 6.8793C21.842 6.87882 21.8415 6.87834 21.841 6.87786C21.7646 6.75487 17.5401 0 11 0ZM11 2.2C13.8351 2.2 16.1333 4.49827 16.1333 7.33333C16.1333 10.1684 13.8351 12.4667 11 12.4667C8.16493 12.4667 5.86667 10.1684 5.86667 7.33333C5.86667 4.49827 8.16493 2.2 11 2.2ZM11 5.13333C10.4165 5.13333 9.85695 5.36512 9.44436 5.7777C9.03179 6.19028 8.8 6.74986 8.8 7.33333C8.8 7.91681 9.03179 8.47639 9.44436 8.88897C9.85695 9.30155 10.4165 9.53333 11 9.53333C11.5835 9.53333 12.1431 9.30155 12.5556 8.88897C12.9682 8.47639 13.2 7.91681 13.2 7.33333C13.2 6.74986 12.9682 6.19028 12.5556 5.7777C12.1431 5.36512 11.5835 5.13333 11 5.13333Z"
        fill={colors.grey[800]}
      />
    </SvgIcon>
  )
}

export default Visibility
