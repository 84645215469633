export enum PasswordStrengthScore {
  NONE,
  WEAK,
  MEDIUM,
  STRONG,
}

const hasShortLengthRegExp = new RegExp(/^.{1,4}$/)
const hasMediumLengthRegExp = new RegExp(/^.{5,}$/)
const hasLongLengthRegExp = new RegExp(/^.{8,}$/)
const hasNumbersRegExp = new RegExp(/\d/)
const hasCapitalLettersRegExp = new RegExp(/[A-Z]/)
const hasLowerLettersRegExp = new RegExp(/[a-z]/)
const hasSpecialCharsRegExp = new RegExp(/[\s\\"$%&'().+\-/:;<>=[\]^_`{}|~*!@#?]/)

export const calculateScore = (password = ''): PasswordStrengthScore => {
  const hasShortLength = hasShortLengthRegExp.test(password)
  const hasMediumLength = hasMediumLengthRegExp.test(password)
  const hasLongLength = hasLongLengthRegExp.test(password)
  const hasNumbers = hasNumbersRegExp.test(password)
  const hasCapitalLetters = hasCapitalLettersRegExp.test(password)
  const hasLowerLetters = hasLowerLettersRegExp.test(password)
  const hasSpecialChars = hasSpecialCharsRegExp.test(password)
  const hasAllChartTypes = hasNumbers && hasCapitalLetters && hasSpecialChars && hasLowerLetters

  if (hasLongLength && hasAllChartTypes) return PasswordStrengthScore.STRONG

  if (hasMediumLength) return PasswordStrengthScore.MEDIUM

  if (hasShortLength) return PasswordStrengthScore.WEAK

  return PasswordStrengthScore.NONE
}
