import React, { ReactNode } from 'react'

import { FieldError } from 'react-hook-form'

import { FormControl, Checkbox as BaseCheckbox, CheckboxProps, FormHelperText } from '@mui/material'

import * as S from './Checkbox.styles'

type Props = CheckboxProps & {
  label: string | ReactNode
  inputError?: FieldError
  align?: 'top' | 'center'
  dataTestId?: string
}

const Checkbox = ({ inputError, label, dataTestId, align = 'top', ...props }: Props) => {
  return (
    <FormControl error={Boolean(inputError)} fullWidth data-testid={dataTestId} variant="filled">
      <S.FormControlLabel
        label={typeof label === 'string' ? <div dangerouslySetInnerHTML={{ __html: label }} /> : label}
        align={align}
        control={<BaseCheckbox {...props} />}
      />

      {inputError?.message && <FormHelperText>{inputError.message}</FormHelperText>}
    </FormControl>
  )
}

export default Checkbox
