import { InputAdornment as BaseInputAdornment, inputAdornmentClasses } from '@mui/material'
import { styled } from '@mui/material/styles'

const { root, positionStart, hiddenLabel } = inputAdornmentClasses

const InputAdornment = styled(BaseInputAdornment)(({ theme }) => ({
  color: theme.palette.grey[800],

  [`&.${root}.${positionStart}:not(.${hiddenLabel})`]: {
    marginTop: 0,
  },
}))

export default InputAdornment
