import React from 'react'

import { colors } from '@dentalux/ui-library-core/cjs/constants/colors/colors'

import { SvgIcon, SvgIconProps } from '@mui/material'

const Drafts = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 19" sx={{ width: 20, height: 19 }}>
      <path
        d="M19.99 6.6875C19.99 5.9675 19.62 5.3375 19.05 4.9875L11.01 0.2775C10.39 -0.0925 9.61 -0.0925 8.99 0.2775L0.95 4.9875C0.38 5.3375 0 5.9675 0 6.6875V16.6875C0 17.7875 0.9 18.6875 2 18.6875H18C19.1 18.6875 20 17.7875 20 16.6875L19.99 6.6875ZM8.94 11.0275L1.74 6.5275L8.99 2.2775C9.61 1.9075 10.39 1.9075 11.01 2.2775L18.26 6.5275L11.06 11.0275C10.41 11.4275 9.59 11.4275 8.94 11.0275Z"
        fill={colors.grey[800]}
      />
    </SvgIcon>
  )
}

export default Drafts
