import React, { useEffect, useState } from 'react'

import { FieldError } from 'react-hook-form'
import MaskedInput from 'react-text-mask'

import { FormControl, FilledInputProps, FormHelperText } from '@mui/material'

import BDayIcon from 'src/components/icons/BDay'

import { Input } from '../FilledInput/FilledInput.styles'
import StyledInputAdornment from '../InputAdornment'
import StyledInputLabel from '../InputLabel'

type Props = FilledInputProps & {
  label?: string
  inputError?: FieldError
  required?: boolean
  dataTestId?: string
}

const BirthDayInputFilled = ({ inputError, label = 'Birthday', required, value = '', dataTestId, ...rest }: Props) => {
  const idPrefix = label.toLocaleLowerCase()
  const [shrinkLabel, setShrinkLabel] = useState<boolean>(!!value)

  useEffect(() => {
    if (value) setShrinkLabel(true)
  }, [value])

  return (
    <FormControl fullWidth variant="filled" data-testid={dataTestId}>
      <StyledInputLabel
        error={Boolean(inputError)}
        shrink={shrinkLabel}
        htmlFor={`${idPrefix}-input`}
        required={required}
        labelOffset={32}
      >
        {label}
      </StyledInputLabel>
      <MaskedInput
        {...rest}
        id={`${idPrefix}-input`}
        value={value}
        mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
        required={required}
        onFocus={() => setShrinkLabel(true)}
        placeholder={shrinkLabel ? 'dd.mm.yyyy' : undefined}
        render={(ref, props) => (
          <Input
            {...props}
            error={Boolean(inputError)}
            inputRef={ref}
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]-',
              onBlur: () => {
                if (!value) {
                  setShrinkLabel(false)
                }
              },
            }}
            startAdornment={
              <StyledInputAdornment position="start">
                <BDayIcon />
              </StyledInputAdornment>
            }
          />
        )}
      />

      <FormHelperText error={Boolean(inputError)} id={`${idPrefix}-input-helper-text`}>
        {inputError?.message}
      </FormHelperText>
    </FormControl>
  )
}

export default BirthDayInputFilled
