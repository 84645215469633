import React, { useState, useCallback, useEffect, useRef, ReactNode } from 'react'

import { FieldError } from 'react-hook-form'

import { FormControl, FilledInputProps, InputProps, FormHelperText } from '@mui/material'

import StyledInputLabel from '../InputLabel'

import * as S from './FilledInput.styles'

type Props = FilledInputProps & {
  label: string
  hideHelperText?: boolean
  InputProps?: InputProps
  inputError?: FieldError
  required?: boolean
  children?: ReactNode | ReactNode[]
  onAutoFill?: () => void
  shrink?: boolean
  dataTestId?: string
}

const FilledInput = ({
  inputError,
  label,
  required,
  onBlur,
  onFocus,
  onAnimationStart,
  onAutoFill,
  children,
  hideHelperText,
  shrink,
  InputProps,
  dataTestId,
  autoFocus,
  disabled,
  ...rest
}: Props) => {
  const idPrefix = label.toLocaleLowerCase()
  /**
   * Logic related to shrinking of the label is a workaround
   * for an issue with the current version of MUI 5.1.0
   * The issue occurs when there is a start input adornment,
   * which causes the label to be shrinked at all times
   */
  const [shrinkLabel, setShrinkLabel] = useState<boolean>(Boolean(rest.value))

  const ref = useRef<HTMLInputElement>()

  useEffect(() => {
    if (autoFocus) {
      ref.current.focus()
    }
  }, [])

  const handleFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setShrinkLabel(true)

      if (onFocus) onFocus(event)
    },
    [onFocus]
  )

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (event.target?.value?.length === 0) {
        setShrinkLabel(false)
      }

      if (onBlur) onBlur(event)
    },
    [onBlur]
  )

  const handleAnimationStart = (e) => {
    if (e.animationName === 'mui-auto-fill') {
      setShrinkLabel(true)
      onAutoFill && onAutoFill()
    }

    onAnimationStart && onAnimationStart(e)
  }

  useEffect(() => {
    if (rest.value) {
      setShrinkLabel(true)
    }
  }, [rest.value])

  return (
    <FormControl fullWidth variant="filled" data-testid={dataTestId}>
      <StyledInputLabel
        error={Boolean(inputError)}
        shrink={shrink || shrinkLabel}
        htmlFor={`${idPrefix}-input`}
        labelOffset={rest?.startAdornment ? 32 : 0}
        disabled={disabled}
        required={required}
      >
        {label}
      </StyledInputLabel>
      <S.Input
        {...rest}
        {...InputProps}
        id={`${idPrefix}-input`}
        error={Boolean(inputError)}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onAnimationStart={handleAnimationStart}
        disabled={disabled}
        inputRef={ref}
      />
      {!hideHelperText && (
        <FormHelperText error={Boolean(inputError)} id={`${idPrefix}-input-helper-text`}>
          {inputError?.message}
        </FormHelperText>
      )}
      {children}
    </FormControl>
  )
}

export default FilledInput
