import React from 'react'

import { colors } from '@dentalux/ui-library-core/cjs/constants/colors/colors'

import { SvgIcon, SvgIconProps } from '@mui/material'

const VisibilityOff = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24" sx={{ width: 24, height: 24 }}>
      <path
        d="M2.25182 1L1.21484 2.03698L21.7482 22.5703L22.7852 21.5333L18.3665 17.1147C21.3701 15.081 22.8527 12.2374 22.8725 12.1991C22.9557 12.0771 23.0002 11.9328 23 11.7852C22.9999 11.6197 22.9439 11.4592 22.841 11.3297C22.7646 11.2067 18.5401 4.45182 12 4.45182C10.1446 4.45182 8.47951 5.00196 7.03854 5.78672L2.25182 1ZM12 6.65182C14.8351 6.65182 17.1333 8.95009 17.1333 11.7852C17.1333 12.9379 16.7447 13.9946 16.1021 14.8503L13.9809 12.729C14.1191 12.4436 14.2 12.1246 14.2 11.7852C14.2 10.57 13.2151 9.58516 12 9.58516C11.6606 9.58516 11.3416 9.66608 11.0561 9.8043L8.9349 7.68307C9.79058 7.04046 10.8472 6.65182 12 6.65182ZM4.63086 7.44102C2.45138 9.27431 1.20165 11.2623 1.16042 11.3283C1.05675 11.458 1.00019 11.6191 1 11.7852C1.00013 11.9271 1.04143 12.0659 1.11888 12.1848C1.11983 12.1862 1.12078 12.1876 1.12174 12.1891C1.13435 12.2151 4.68048 19.1185 12 19.1185C13.3567 19.1185 14.5817 18.879 15.681 18.4926L13.7832 16.5934C13.2266 16.8002 12.6277 16.9185 12 16.9185C9.16493 16.9185 6.86667 14.6202 6.86667 11.7852C6.86667 11.1574 6.985 10.5586 7.1918 10.002L4.63086 7.44102Z"
        fill={colors.grey[800]}
      />
    </SvgIcon>
  )
}

export default VisibilityOff
