import React, { ReactNode } from 'react'

import { FieldError } from 'react-hook-form'

import { FilledInputProps } from '@mui/material'

import Person from 'src/components/icons/Person'

import FilledInput from '../FilledInput'
import StyledInputAdornment from '../InputAdornment'

type Props = FilledInputProps & {
  inputError?: FieldError
  required?: boolean
  label?: string
  children?: ReactNode | ReactNode[]
  dataTestId?: string
}

const NameInputFilled = ({ label = 'Name', ...rest }: Props) => (
  <FilledInput
    {...rest}
    type="text"
    label={label}
    startAdornment={
      <StyledInputAdornment position="start">
        <Person />
      </StyledInputAdornment>
    }
  />
)

export default NameInputFilled
