import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

import { PasswordStrengthScore } from './PasswordStrength.helpers'

const getStrenghtStyle = (strength: keyof typeof PasswordStrengthScore, theme) => {
  const stylesByStrenght = {
    WEAK: {
      '& .indicate': {
        background: theme.palette.error[500],
      },
      '& .label': {
        color: theme.palette.error[500],
      },
    },
    MEDIUM: {
      '& .indicate': {
        background: theme.palette.secondary[500],
      },
      '& .label': {
        color: theme.palette.secondary[500],
      },
    },
    STRONG: {
      '& .indicate': {
        background: theme.palette.success.main,
      },
      '& .label': {
        color: theme.palette.success.main,
      },
    },
  }

  return stylesByStrenght[strength]
}

export const Wrapper = styled('div')<{ strength: keyof typeof PasswordStrengthScore }>(({ theme, strength }) => ({
  '.indicator + .indicator': {
    marginLeft: theme.spacing(0.25),
  },
  ...getStrenghtStyle(strength, theme),
}))

export const Label = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(0.5),
  textTransform: 'capitalize',
}))

export const Hint = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary[800],
}))

export const Indicator = styled(Typography)(() => ({
  display: 'inline-block',
  width: '100%',
  height: 3,
}))
