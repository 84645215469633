import { TextField } from '@mui/material'
import { styled } from '@mui/material/styles'

export const OtopInputContainer = styled('form')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: theme.spacing(1),

  '& .otpInput + .otpInput': {
    marginLeft: theme.spacing(1.5),
  },
}))

export const OtpInputField = styled(TextField)(({ theme }) => ({
  width: 56,
  height: 56,
  borderRadius: 8,
  boxShadow: '0px 1px 3px rgba(0, 71, 52, 0.2)',
  justifyContent: 'center',
  backgroundColor: theme.palette.common.white,

  '&:focus-within': {
    backgroundColor: theme.palette.primary['50'],
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
  },

  '& input': {
    padding: 0,
    textAlign: 'center',
    ...theme.typography['Lato H4'],
    color: theme.palette.grey[800],

    '&:disabled': {
      cursor: 'not-allowed',
    },
  },

  '& fieldset': {
    border: 'none',
  },

  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
}))
