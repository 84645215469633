import React from 'react'

import { colors } from '@dentalux/ui-library-core/cjs/constants/colors/colors'

import { SvgIcon, SvgIconProps } from '@mui/material'

const Lock = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 18 21" sx={{ width: 18, height: 21 }}>
      <path
        d="M9.0002 0.600006C5.91673 0.600006 3.4002 3.11654 3.4002 6.20001V7.80001H1.8002C0.916195 7.80001 0.200195 8.51601 0.200195 9.40001V19C0.200195 19.884 0.916195 20.6 1.8002 20.6H16.2002C17.0842 20.6 17.8002 19.884 17.8002 19V9.40001C17.8002 8.51601 17.0842 7.80001 16.2002 7.80001H14.6002V6.20001C14.6002 3.21727 12.2295 0.814858 9.28457 0.657819C9.1941 0.621374 9.09772 0.601781 9.0002 0.600006V0.600006ZM9.0002 2.20001C11.2191 2.20001 13.0002 3.98107 13.0002 6.20001V7.80001H5.0002V6.20001C5.0002 3.98107 6.78126 2.20001 9.0002 2.20001ZM4.2002 12.6C5.0842 12.6 5.8002 13.316 5.8002 14.2C5.8002 15.0832 5.0842 15.8 4.2002 15.8C3.3162 15.8 2.6002 15.0832 2.6002 14.2C2.6002 13.316 3.3162 12.6 4.2002 12.6ZM9.0002 12.6C9.8842 12.6 10.6002 13.316 10.6002 14.2C10.6002 15.0832 9.8842 15.8 9.0002 15.8C8.1162 15.8 7.4002 15.0832 7.4002 14.2C7.4002 13.316 8.1162 12.6 9.0002 12.6ZM13.8002 12.6C14.6842 12.6 15.4002 13.316 15.4002 14.2C15.4002 15.0832 14.6842 15.8 13.8002 15.8C12.9162 15.8 12.2002 15.0832 12.2002 14.2C12.2002 13.316 12.9162 12.6 13.8002 12.6Z"
        fill={colors.grey[800]}
      />
    </SvgIcon>
  )
}

export default Lock
