import React, { ReactNode } from 'react'

import { FieldError } from 'react-hook-form'

import { FilledInputProps } from '@mui/material'

import Drafts from 'src/components/icons/Drafts'

import FilledInput from '../FilledInput'
import StyledInputAdornment from '../InputAdornment'

type Props = FilledInputProps & {
  onAutoFill?: () => void
  inputError?: FieldError
  required?: boolean
  label?: string
  children?: ReactNode | ReactNode[]
  autoComplete?: string
  dataTestId?: string
}

const EmailInputFilled = ({ label = 'E-mail', autoComplete, ...rest }: Props) => (
  <FilledInput
    {...rest}
    label={label}
    type="email"
    autoComplete={autoComplete || 'email'}
    startAdornment={
      <StyledInputAdornment position="start">
        <Drafts />
      </StyledInputAdornment>
    }
  />
)

export default EmailInputFilled
