export const parseMobilePhoneNumber = async (phone: string) => {
  const parsePhoneNumberFromString = (await import('libphonenumber-js/mobile')).parsePhoneNumberFromString

  const phoneNumber = parsePhoneNumberFromString(phone || '')

  return {
    country: phoneNumber?.country ?? 'DE',
    nationalNumber: phoneNumber?.nationalNumber ?? '',
    countryCallingCode: phoneNumber?.countryCallingCode ?? '49',
  }
}

export const removeLeadingZero = async (mobilePhone: string) => {
  const { countryCallingCode, nationalNumber } = await parseMobilePhoneNumber(mobilePhone)

  return `+${countryCallingCode}${nationalNumber.replace(/^0/, '')}`
}
